<post-tabs>
  <post-tab-header slot="tabs" panel="opening-hours" [hidden]="!poi.hasOpeningHours">{{getTabTranslationByKey('opening-hours') | translate}}</post-tab-header>
  <post-tab-header slot="tabs" panel="deadlines" [hidden]="!poi.hasDeadlines">{{getTabTranslationByKey('deadlines') | translate}}</post-tab-header>
  <post-tab-header slot="tabs" panel="services" [hidden]="!poi.hasServices">{{getTabTranslationByKey('services') | translate}}</post-tab-header>
  <post-tab-header slot="tabs" panel="addresses" [hidden]="!poi.hasAddresses">{{getTabTranslationByKey('addresses') | translate}}</post-tab-header>
  <post-tab-header slot="tabs" panel="partners" [hidden]="!poi.hasPartners">{{getTabTranslationByKey('partners') | translate}}</post-tab-header>

  <!-- Öffungszeiten -->
  <post-tab-panel name="opening-hours" [hidden]="!poi.hasOpeningHours">
    <app-openinghours [poi]="poi"></app-openinghours>
  </post-tab-panel>

  <!-- Annahmeschluss -->
  <post-tab-panel name="deadlines" [hidden]="!poi.hasDeadlines">
    <app-deadlines [poi]="poi"></app-deadlines>
  </post-tab-panel>

  <!-- Services -->
  <post-tab-panel name="services" [hidden]="!poi.hasServices">
    <app-services [poi]="poi"></app-services>
  </post-tab-panel>

  <!-- Adressierung PickPost & Postlagernd -->
  <post-tab-panel name="addresses" [hidden]="!poi.hasAddresses">
    <app-addressing [poi]="poi"></app-addressing>
  </post-tab-panel>

  <!-- Partnerangebote -->
  <post-tab-panel name="partners" [hidden]="!poi.hasPartners">
    <app-partners [poi]="poi"></app-partners>
  </post-tab-panel>
</post-tabs>