import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { TagManagerService } from '@app/app/common/tag.service';
import { forkJoin } from 'rxjs';
import { TranslateExtendedService } from 'src/app/common/translate-extended.service';
import { PoiDetail } from 'src/app/gis/model/poidetail/poidetail';

@Component({
  selector: 'app-detail-tabs',
  templateUrl: './detail-tabs.component.html',
})
export class DetailTabsComponent implements AfterViewInit {
  @Input() poi: PoiDetail;
  public activeTab = '';

  private tabChangeListener: (event: CustomEvent) => void;

  private tabsToId: { id: string; key: string }[] = [
    { id: 'opening-hours', key: 'detail.hours.hours' },
    { id: 'deadlines', key: 'detail.hours.deadline' },
    { id: 'services', key: 'detail.services.service' },
    { id: 'addresses', key: 'detail.addressing.pickpostAndPostlagernd' },
    { id: 'partners', key: 'detail.partners.offers' },
  ];


  constructor(
    private readonly translateExtendedService: TranslateExtendedService,
    private readonly tagManagerService: TagManagerService,
    @Inject(PLATFORM_ID) private platformId: any) { }

  ngAfterViewInit() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const tabs = document.querySelector('post-tabs');
    this.tabChangeListener = (event: CustomEvent) => {
      this.activeTab = event.detail as string;

      forkJoin([this.translateExtendedService.get(this.getTabTranslationByKey(event.detail)),
      this.translateExtendedService.getGermanTranslation(this.getTabTranslationByKey(event.detail))])
        .subscribe(([navTitle, navTitleGer]) => {
          /* eslint-disable @typescript-eslint/naming-convention */
          this.tagManagerService.click({
            event: 'select_menu',
            type: 'tab',
            label: navTitleGer.toLowerCase().replace(/\s/g, '-'),
            text: navTitle.toLowerCase(),
            additional_info: 'poi'
          });
          /* eslint-enable @typescript-eslint/naming-convention */
        }
        );
    };

    tabs.addEventListener('postChange', this.tabChangeListener);
  }

  public getTabTranslationByKey(id: string) {
    return this.tabsToId.find((e) => e.id === id).key;
  }
}
